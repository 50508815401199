const iconBlockAdapter = (data) => {
  return {
    text: data.text,
    num: data.num,
  };
};

export default ({ data }) => {
  return {
    title: data.title,
    items: data.items?.map(iconBlockAdapter),
  };
};
